.card-container {
  width: 700px;
  color: #f0fff0;
  border: 2px solid #f0fff0;
  margin: 0 auto;
  min-height: 250px;
  background: rgba(0, 0, 0, 0.35);


  .title {
    font-size: 2.0rem;
    border-left: 4px solid #f0fff0;
    border-bottom: 3px solid #f0fff0;
    margin: 10px;
    padding: 0 0 3px 9px;
  }

  .contents {
    margin: 10px;
    font-size: 1rem;
    line-height: 1.7rem;

    li {
      list-style: disc inside;

      a {
        color: #04dbda;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}