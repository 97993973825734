.icon {
  width: 300px;
  height: 300px;
  margin: 0 auto 0 auto;
  border-radius: 150px;
  border: 5px solid rgba(13, 107, 179, 0.4);
  background: rgba(0, 0, 0, 0.35);
}

.name {
  position: relative;
  margin: 0 auto 0 auto;
  color: #f0fff0;
  font-size: 2.5rem;
  padding: 20px;

  &:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 blue;
    padding: 20px;
    top: 0;
    color: white;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
  }

  &:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 red;
    padding: 20px;
    top: 0;
    color: white;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
  }
}

@keyframes noise-anim {
  $steps: 20;

  @for $i from 0 through $steps {
    #{ percentage($i * (1 / $steps) ) } {
      clip: rect(random(100) + px, 9999px, random(100) + px, 0);
    }
  }
}

@keyframes noise-anim-2 {
  $steps: 20;

  @for $i from 0 through $steps {
    #{ percentage($i * ( 1 / $steps) ) } {
      clip: rect(random(100) + px, 9999px, random(100) + px, 0);
    }
  }
}
