.navigation {
  display: flex;
  justify-content: center;
  height: 5rem;
}

@media (max-width: 950px) {
  .navigation {
    display: none;
  }
}

.navigation li {
  margin: 0 2.8rem;
}

.navigation li a {
  position: relative;
  color: #f0fff0;
  line-height: 5rem;
  font-size: 1.15rem;
}

.navigation li a:hover {
  -webkit-text-fill-color: #f0fff0;
  -webkit-text-stroke: 0.5px #04dbda;
}

.navigation li a::before {
  content: "";
  position: absolute;
  background-color: #04dbda;
  width: 0%;
  height: 2px;
  bottom: -0.4rem;
  transition: width 300ms 0s linear;
}

.navigation li a:hover::before {
  width: 100%;
}

.current-page {
  -webkit-text-fill-color: #f0fff0;
  -webkit-text-stroke: 0.5px #04dbda;
}

.current-page::before {
  width: 100% !important;
}